@tailwind base;
@tailwind components;
@tailwind utilities;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --yadada-blue: #5465ff;
  --cool-gray-300: #d1d5db;
}
